import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
const Partner = () => {
  const goTocomp = () => {
    return window.open("https://www.whiteboard.chat/", "__blank");
  };
  return (
    <div className={styles.main}>
      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>Whiteboard.chat</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL + "/assets/images/whiteboard/Whiteboard.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
              Free Interactive Online Whiteboard For Teachers And Students To
              Collaborate In A Digital Classroom
            </h3>

            <p>
              We are a Web-Based Learning Ecosystem and have partnered with some
              of the biggest players in internet-based online education.
            </p>

            <p>
              A Digital Whiteboard Designed for teaching helps easily observe
              all of your student's individual online whiteboards and engage
              with them one on one. Whiteboard.chat also works across all
              internet-enabled devices, including chromebooks and mobile phones.
              You can connect from any device free of charge, create lessons,
              collaborate with students and share with colleagues. Almost all
              functionality of our internet whiteboard is available to you free
              of charge.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default Partner;
