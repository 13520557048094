import React, { useEffect, useRef } from "react";
import { Button, Grid } from "@mui/material";
import { gsap } from "gsap";
import { TweenMax } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GoToTop from "../../GoToTop";
import styles from "../../styles/DataTemplate.module.css";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function DataTemplate() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  revealsFirstRef.current = [];

  const history = useHistory();

  const openpositionHandler = () => {
    history.push({
      pathname: "/openpositions",
      state: true,
    });
  };
  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  }, []);

  useEffect(() => {
    revealsFirstRef.current.forEach((el, index) => {
      TweenMax.fromTo(
        el,
        1.5,
        {
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          delay: 0,
        },
        {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          delay: 0,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",
          },
        }
      );
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };
  return (
    <div className={styles.datatemplate_container}>
      <GoToTop />
      <div className={styles.dt}>
        <div ref={detailRef}>
          <div className={styles["dt-title"]}>
            <p className={styles["dt-title-p"]}>Life at Data Template</p>
            <h3 className={styles["dt-heading"]}>
              <FormattedMessage id="dt_heading_life" />
            </h3>
          </div>

          <div>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                ref={addToFirstRef}>
                <div className={styles["dt-img"]}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/lifeatdt/Lifeatdt.svg"
                    }
                    className={styles["dt-future-img"]}
                    alt='dt'
                    loading='lazy'></img>
                </div>
              </Grid>
              <div ref={detailRef} className={styles["dt-items"]}>
                <p className={styles["dt-items-p"]}>
                  <FormattedMessage id="dt_items_p_life" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="dt_h6_life" />
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="dt_items_p1_life" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="and_values_life" />
                </h6>

                <ul className={styles["dt-ul"]}>
                  <p>
                    <FormattedMessage id="and_values_life_p" />
                  </p>
                </ul>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="aspirations_life" />
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="aspirations_life_p" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="teamwork_life" />{" "}
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="teamwork_life_p" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="develop_something_life" />{" "}
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="develop_something_life_p" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="no_matter_life" />{" "}
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="no_matter_life_p" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="your_mental_life" />{" "}
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="your_mental_life_p" />
                </p>

                <h6 className={styles["dt-h6"]}>
                  <FormattedMessage id="we_build_life" />{" "}
                </h6>
                <p className={styles["dt-items-p1"]}>
                  <FormattedMessage id="we_build_life_p" />
                  <div className={styles["discover-btn"]}>
                    <Button
                      variant='outlined'
                      type='submit'
                      style={{ textTransform: "capitalize" }}
                      onClick={openpositionHandler}>
                      <FormattedMessage id="button_life" />
                    </Button>
                  </div>
                </p>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
