import React, { useState, useEffect } from "react";
import { Button, Checkbox, TextField } from "@mui/material";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Reachout.module.css";
import * as data from '../../../data.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function ReachOut() {
  const history = useHistory()
  const [checkbox, setCheckbox] = useState(false);
  const [contact, setConatct] = React.useState(null)
  const initialValues = {
    name: "",
    email: "",
    mob: "",
    company: "",
    mes: "",
  };






  const validate = (values) => {
    let errors = {};


    const emailregex =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const mob = /^[7-9][0-9]{9}$/
    const nameregex =/^[a-zA-Z\u00C0-\u017F]+(?:\s[a-zA-Z\u00C0-\u017F]+)*$/




    if (!values.name) {
      errors.name = "Name is required";
    } else if(values.name.length > 30){
      errors.name = 'Only 30 Characters are required!'
    } else if(!nameregex.test(values.name)){
      errors.name = "Invalid name"
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailregex.test(values.email)) {
      errors.email = "Invalid Email";
    }
    if (!values.mob) {
      errors.mob = "Mobile Number is required";
    } else if (!mob.test(values.mob)) {
      errors.mob = "Invalid Number";
    }
    if (!values.mes) {
      errors.mes = "Message is required";
    }
    if (values.company.length > 30) {
      errors.company = "Only 30 Characters are required!";
    } else if(!nameregex.test(values.company)){
      errors.company = "Invalid input"
    }





    return errors;
  };


  const CheckBoxHandler = (e) => {
    setCheckbox(e.target.checked)
  }


  useEffect(() => {
    if (data && data.wpData && data.wpData[0].contact) {
      setConatct(data.wpData[0].contact)
    }

  }, [data])
  const notify = (data) => toast(data, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  function submitConatct(data) {
    fetch(contact, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({

        "name": data.name,
        "phone": data.mob,
        "email": data.email,
        "company": data.company,
        "message": data.mes

      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCheckbox(false);
        notify("Thanks for contacting us we will be in touch with you shortly");
      })
      .catch((error) => {
        notify("Thanks for contacting us we will be in touch with you shortly");
      });
  }


  const contactFormSubmitHandler = (values, { resetForm }) => {
    const data = 
   {...values,mob:Number(values.mob),checkbox}

    submitConatct(data)
    setCheckbox(false)
    resetForm();

  }

  const privacyPolicyHAndler = () => {
    history.push('/privacypolicy')
  }
  const termHandler = () => {
    history.push('/termsofuse')
  }


  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={contactFormSubmitHandler}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty
        } = formik;
        return (
          <div className={styles["reactout-with-us"]}>
            <GoToTop />

            <div className={styles["form-block"]}>
              <ToastContainer />
              <form onSubmit={handleSubmit} autoComplete="off">
                <h3 className={styles["Reach-H"]}>
                  <FormattedMessage id="contact_us_reachout" />
                </h3>
                <label htmlFor="name" className={styles["form-label"]}>
                  <FormattedMessage id="name_reachout" />
                  <br />
                  <TextField

                    id="name"
                    variant="standard"
                    className={styles["text-fields"]}
                    size='small'
                    type='text'
                    value={values.name}

                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {errors.name && touched.name && (
                    <span className={styles.error}>{errors.name}</span>
                  )}
                </label>
                <br />
                <label htmlFor="email" className={styles["form-label"]}>
                  <FormattedMessage id="reach_me_reachout" />
                  <br />
                  <TextField

                    id="email"
                    variant="standard"
                    className={styles["text-fields"]}
                    size='small'
                    type='email'
                    value={values.email}

                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {errors.email && touched.email && (
                    <span className={styles.error}>{errors.email}</span>
                  )}
                </label>
                <br />
                <label htmlFor="mob" className={styles["form-label"]}>
                  <FormattedMessage id="mobile_reachout" /> <br />
                  <TextField

                    id="mob"
                    variant="standard"
                    className={styles["text-fields"]}
                    type='text'
                    size='small'
                    value={values.mob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {errors.mob && touched.mob && (
                    <span className={styles.error}>{errors.mob}</span>
                  )}
                </label>
                <br />
                <label htmlFor="company" className={styles["form-label"]}>
                  <FormattedMessage id="i_work_at" />
                  <br />
                  <TextField

                    id="company"
                    variant="standard"
                    className={styles["text-fields"]}
                    type='text'
                    size='small'
                    value={values.company}

                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {errors.company && touched.company && (
                    <span className={styles.error}>{errors.company}</span>
                  )}
                </label>
                <br />
                <label htmlFor="mes" className={styles["form-label"]}>
                  <FormattedMessage id="message_reachout" />
                  <br />
                  <TextField

                    id="mes"
                    variant="standard"
                    className={styles["text-fields"]}
                    size='small'
                    value={values.mes}

                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {errors.mes && touched.mes && (
                    <span className={styles.error}>{errors.mes}</span>
                  )}

                </label>
                <div className={styles.checkboxItems}>
                  {<Checkbox className={styles.check} checked={checkbox} onChange={CheckBoxHandler} value={checkbox}/>}
                  <p className={styles.acknowledge}>
                    <FormattedMessage id="acknowledge_reachout" />{" "}
                    <span className={styles.termPriv} onClick={termHandler}>
                      <FormattedMessage id="terms_reachout" />
                    </span>{" "}
                    <FormattedMessage id="and_reachout" />{" "}
                    <span
                      className={styles.termPriv}
                      onClick={privacyPolicyHAndler}
                    >
                      <FormattedMessage id="privacy_reachout" />
                    </span>
                    .
                  </p>
                </div>


                <Button
                  className={styles.contactButton} variant="outlined"
                type="submit" disabled={!checkbox}>
                  <FormattedMessage id="button_reachout" />
                </Button>
              </form>
            </div>
          </div>
        )
      }}
    </Formik >
  );
}
