import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/CaseStudies.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import GoToTop from "../../GoToTop";
import CloseIcon from '@mui/icons-material/Close';
import Search from "../CustomSearch/Search";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
export default function CaseStudies({ currentLanguage }) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'casestudies_search' });
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });

  gsap.registerPlugin(ScrollTrigger);
  const searchParam = window.location.search;
  const params = new URLSearchParams(searchParam);
  const lang = params.get("lang");
  const detailRef = useRef(null);
  const [data, setData] = useState(languageData[currentLanguage]);
  const [datas, setDatas] = useState([]);
  const [industries,setIndustries] = useState('');
  const [services, setSerrvices] = useState('');
  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([]);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const history = useHistory()
  const route = useRouteMatch()
  const {industry} = useParams()



  useEffect(()=>{
    let filterCaseStudies = datas && datas?.filter((caseStudies) => {
      return (
        caseStudies.title
              ?.toLowerCase()
              .includes(search.toLowerCase())
             
      )
      
  })


    if(industries.length > 0 && search.length >= 0){
      filterCaseStudies = filterCaseStudies?.filter((data) => {
        return (
          (data?.industry.includes(industries)) && data.title.toLowerCase().includes(search.toLowerCase()) ||
       (data?.service.includes(industries)) && data.title.toLowerCase().includes(search.toLowerCase())
    )
  }) 
}
const sortedData = filterCaseStudies?.sort((a,b) => a.title.localeCompare(b.title))
filterCaseStudies && setFilterData(sortedData)
},[datas,search,industries,route.path])

useEffect(() =>{
  if(data && data?.caseimgs2.length > 0){
    setDatas(data?.caseimgs2)
  }
  if(industry && industry.length > 0){
    setIndustries(industry)
  }
  if(industry === undefined){
    setIndustries('')
  }
  
},[data,industry,route.path])


  const caseStudiesChangeHandler = (e) => {
    setSearch(e.target.value)
  }
  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };

  const clickHandler = () => {
    history.push('network')
  }

  const casecloseButtonHandler = () => {
    setSearch('')
  }

  return (
    <>
      <GoToTop/>
      <div style={styles.casestudiesContainer}>
        
      <div  className={styles.aboutUs} ref={detailRef}>
          <div className={styles["case-title"]}>
            <h1 className={styles.caseheading}>
              <FormattedMessage id="head_casestudies" />
            </h1>
          </div>


          <div className={styles["input-icon my-3 ml-auto"]}>
            <Search
              id='search'
              type='search'
              className={styles.searchCase}
              placeholder={placeholder}
              tabIndex='1'
              aria-label='Search'
              onChange={caseStudiesChangeHandler}
              value={search}
              onClick={casecloseButtonHandler}
              closeBtn={true}
            />

          </div>
        </div>
        <div className={styles["casestudy-div"]}>
          <Grid id='case-part' className={styles['case-part']} container spacing={2}>
            {filterData && filterData?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    className={styles["casestudies-img"]}
                    ref={addToSecondRef}
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}>
                    <div className=''>
                      <Link 
                      New
                      Message
                      to={{
                        pathname: `/casestudies/caseInfo/${item.id}`,
                        search: `?lang=${lang}`,
                      }}
                      >
                        <div className={styles["img-hover-zoom"]}>
                        <img className={styles["case-img"]} id="imghover" src={item.img} alt={item.title} loading="lazy" />
                        </div>
                      </Link>
                      <p className={styles.casetitle}>{item.date}</p>
                      <h4 className={styles.casesubtitle1}>{item.title}</h4>
                    </div>
                  </Grid>
                );
              })}
            {filterData?.length === 0 && <p className={styles['no-case-studies-found']}>No CaseStudies Found</p>}
          </Grid>
        </div>
      </div>
    </>
  );
}
