import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";
import { FormattedMessage } from "react-intl";

export default function Sqlquery() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const detailRef1 = useRef(null);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    const element1 = detailRef1.current;
    gsap.fromTo(
      element1,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  }, [])



  return (
    <div className={styles.web_container}>
      <GoToTop />
      <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "28px" }}>
          <div className={styles["enterprise-block"]} >

            <div>
              <h3 className={styles["enterprise-h3"]}>
                Mastering SQL Query Optimization
              </h3>
            </div>

          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}    >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/blogdrill/Sqlquery/Sqlquery1.svg"
            }
            className={styles["enterprise-img"]}
            alt='enterprise'
          />
        </Grid>
      </Grid>

      <div className={styles.enterpriseMain} ref={detailRef1}>
        <Grid container className={styles.ElementEnterprises}>
          <Grid item>

          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                Optimizing SQL queries
              </h3>

              <p className={styles.PSubHeading}>
                In today’s data-driven world, optimizing SQL queries is crucial for ensuring that your applications run efficiently, and your database performs at its best. Slow queries can lead to sluggish application performance, increased server load, poor user experience and this increases the operational costs.
              </p>

              <h3 className={styles.PHeading}>
                Why SQL Query Optimization Matters
              </h3>

              <p className={styles.PSubHeading}>
                SQL query optimization is crucial for various reasons, impacting performance, resource management, and overall user experience. Here’s why it matters:
                Improved Performance: Speed up data retrieval and processing.
                Resource Efficiency: Minimize CPU, memory, and disk I/O usage.
                Enhanced User Experience: Ensure faster response times and smoother interactions.
                Cost Management: Reduce infrastructure costs associated with resource consumption.

              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                Optimizing the Query
              </h3>

              <p className={styles.PSubHeading}>
                When writing and optimizing a query, several techniques and practices can be employed to ensure it runs efficiently and returns the desired results. Here are some important things to think about
                Analyze and Understand Your Query: Optimization begins with a deep understanding of your query and database structure.
                Examine Query Purpose: Clarify the intent and scope of the SQL statement. What data is it fetching or manipulating?
                Understand Schema and Relationships: Know the relationships between tables, existing indexes, and data distribution. This context is crucial for identifying optimization opportunities.

              </p>

            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>Optimize Query Structure</h3>

              <p className={styles.PSubHeading}>
                When writing and optimizing a query, several techniques and practices can be employed to ensure it runs efficiently and returns the desired results. Here are some important things to think about:
                JOINS: Joins are used to combine rows from two or more tables based on a related column between them. So, use appropriate Joins based on your business requirement.
                WITH (NOLOCK): The WITH (NOLOCK) hint is used to read data without acquiring locks, which can improve query performance and reduce locking contention.
                Temp tables and CTEs: Temporary tables and Common Table Expressions (CTEs) are used to store intermediate results and make complex queries more manageable.
                Temporary Tables: Temporary tables are created in the database session and can store intermediate results. They are useful for breaking down complex queries into simpler steps.
                CTEs (Common Table Expressions): CTEs provide a temporary result set that can be referenced within a SELECT, INSERT, UPDATE, or DELETE statement. They are useful for recursive queries and improving readability.
                So, it is necessary to decide clearly where to use Temp tables and CTEs.
              </p>
              <h3 className={styles.PHeading}>Optimize Table schema</h3>
              <p className={styles.PSubHeading}>
                Optimizing a table schema is essential for enhancing database performance, managing data efficiently, and ensuring the system can handle growing data volumes effectively. Below is a guide on how to optimize a table schema in SQL Server:
                Indexing: Indexing the tables and creating dynamic indexes wherever require. Indexing improves query performance by allowing faster retrieval of records. Indexes can be created for one or more columns in a table.
                Table Partitioning: Partitioning involves dividing a table into smaller, more manageable pieces while maintaining its logical integrity. This can improve query performance, especially for large tables. It will help in reducing the number of logical reads over considerably a large dataset.
              </p>
              <h3 className={styles.PHeading}>Perform Regular Maintenance</h3>
              <p className={styles.PSubHeading}>
                Ongoing maintenance ensures that your database remains in peak condition.
                Routine Maintenance:      Regularly perform database maintenance tasks such as updating statistics and      rebuilding indexes to keep the database optimized.
                Refactor Queries: As data and access patterns evolve, periodically review and refactor queries and schema designs to maintain optimal performance.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ paddingTop: "28px" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/blogdrill/Sqlquery/Sqlquery2.svg"
              }
              className={styles["enterprise-img"]}
              alt='enterprise'
            />
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>Optimization of data storage using Table Partitioning</h3>
              <p className={styles.PSubHeading}>
                Table partitioning is an effective technique to optimize data storage and improve query performance, especially for large tables.
                This technique of dividing large tables into several smaller sections.
                When splitting the large database tables to smaller parts, the number of scans will get reduced.
                The compiler can go check in specific partitions instead of searching in the entire table.
                The goal of Table partitioning is to reduce the overall response time to read and load data for any SQL operations. The two types of Partitioning are:

                Vertical Partitioning
                Involves dividing a table based on columns.
                When tables have many columns, and not all columns are frequently accessed together.

                Horizontal Partitioning
                Divides a table into numerous tables with equal number of columns but less rows.
                Partitions will be performed over any date time columns which is not having a timestamp.
              </p>
            </div>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>Testing and Validation</h3>

              <p className={styles.PSubHeading}>
                Before deploying optimizations, thorough testing is essential.
                Test in a Staging Environment:  Validate performance improvements in a staging environment to avoid      impacting production systems.
                Benchmarking:  Measure query performance before and after optimization to quantify improvements and ensure the effectiveness of changes.
              </p>
            </div>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>Do’s and Don’ts in different query sections</h3>

              <p className={styles.PSubHeading}>
                SELECT

                <p className={styles.PSubHeading}>
                  Avoid ‘SELECT *’ and bring only the required columns
                  USE LTRIM AND RTRIM in selecting Text data to neglect unwanted preceding and trailing spaces.
                  Give meaningful alias for output columns. Especially, for the computed columns.
                  If all you need to know is whether data exists, do not count all the rows. Use COUNT (1) instead of COUNT (*)
                  Don’t misuse DISTINCT to neglect the JOIN duplicates.
                </p></p>

              <p className={styles.PSubHeading}>
                WHERE<p className={styles.PSubHeading}>
                  Don’t inject a hardcoded value. Query cannot be reused. Instead use parameters.
                  Use appropriate datatypes for filter value parameters. It helps interpreter to refrain from internal type casting.
                  Learn to use IN and EXISTS appropriately.
                  Avoid using Join condition columns in WHERE clauses.
                  Avoid using FUNCTIONs in WHERE Clause which requires more computational cost.
                  WHERE clauses are critical components of UPDATE and DELETE operations.
                </p></p>

              <p className={styles.PSubHeading}>
                JOIN<p className={styles.PSubHeading}>
                  <p className={styles.PSubHeading}>Join order: The order in which tables are connected might influence performance.
                    <p className={styles.PSubHeading}>Table size: Joining larger tables will result in huge result set so use appropriate filters along with joins.
                      <p className={styles.PSubHeading}> Duplication: Joins can generate duplicate rows if there is not a unique constraint on the join columns.
                        <p className={styles.PSubHeading}>ü  Indexed Columns: Using indexed columns in the join condition can help optimize SQL join.
                          <p className={styles.PSubHeading}>Join Type: Use inner joins and left outer joins unless you want to include unmatched rows in your results. If you don't want unmatched rows, use an inner join.
                          </p></p></p></p></p></p></p>
              <p className={styles.PSubHeading}>
                Efficient SQL query optimization is pivotal for ensuring high-performing database systems.so, implementing these techniques demands a deep and wider understanding of each database concepts. Any developer can achieve optimization by adopting any of the previously discussed strategies and aligning them with the specific needs of your database requirements.
              </p>
            </div>
          </Grid>
        </Grid>

      </div>
    </div>
  );
}
