import React, { useRef, useEffect } from "react";
import styles from "../../styles/Locations.module.css";
import Grid from "@mui/material/Grid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { duration } from "@mui/material";

import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

export default function Home() {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);
  const box7 = useRef(null);
  const box8 = useRef(null);
  const box9 = useRef(null);

  useEffect(() => {
    const boxes1 = [box1.current, box2.current, box3.current];

    const boxes2 = [box4.current, box5.current, box6.current];

    const boxes3 = [box7.current, box8.current, box9.current];

    boxes1.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes2.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,

        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes3.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,

        delay: index * 0.3,
        duration: 1.5,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });
  }, []);



  return (
    <div className={styles.location_container}>
      <GoToTop />
      <div ref={ref}>
        <div className={styles["location-main-title"]}>
          <h1 className={styles["location-title"]}>
            <FormattedMessage id="head_location" />
          </h1>
        </div>

        <Grid container spacing={4} className={styles["location-container"]}>
          <Grid
            ref={box1}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL + "/assets/images/locations/india.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>

            <div>
              <h3 className={styles["img-sub-h"]}>India</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech Private Limited</p>
              <p className={styles["img-sub-p1"]}>#5, 9 and 10, G.B Palya, Hosur Road, Bangalore 560068, Karnataka, India
</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +91 80
                25730716</p>
            </div>
          </Grid>
          <Grid
            ref={box1}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={process.env.PUBLIC_URL + "/assets/images/locations/us.svg"}
                alt='Location'
                loading='lazy'
              />
            </div>

            <div>
              <h3 className={styles["img-sub-h"]}>United States</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech LLC</p>
              <p className={styles["img-sub-p1"]}>9435 Waterstone Blvd Suite #140-24, Cincinnati, OH 45249, United States</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +1 513 444
                2000
              </p>
            </div>
          </Grid>
          <Grid
            ref={box3}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={process.env.PUBLIC_URL + "/assets/images/locations/uk.svg"}
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>United Kingdom</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech Limited</p>
              <p className={styles["img-sub-p1"]}>71-75 Shelton St, London WC2H 9JQ, UK
</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +44 7424
                359387
              </p>
            </div>
          </Grid>
          <Grid
            ref={box3}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={process.env.PUBLIC_URL + "/assets/images/locations/mangalore.svg"}
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>India</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech Limited</p>
              <p className={styles["img-sub-p1"]}>Manasa Towers, 4th Floor, M G Road, Kodialbail, Mangalore 575003, Dakshina Kannada District, Karnataka, India
</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +91 824
                2222255
              </p>
            </div>
          </Grid>
          <Grid
            ref={box4}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                id='imghover'
                className={styles["img-res"]}
                src={
                  process.env.PUBLIC_URL + "/assets/images/locations/rwanda.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Rwanda</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech Africa Limited</p>
              <p className={styles["img-sub-p1"]}>District Gasabo, Sector GIsozi, Ruhango, Rukeli, KG 794, Kigali Rwanda</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +250 788 877
                758
              </p>
            </div>
          </Grid>
          <Grid
            ref={box5}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/locations/ukraine.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Ukraine</h3>
              <p className={styles["img-sub-p"]}>Data Template San Infotech LLC</p>
              <p className={styles["img-sub-p1"]}>03142,Street Polladina Ave,22/156,Kiev,Ukraine</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +380 50 338
                2773
              </p>
            </div>
          </Grid>
          <Grid
            ref={box6}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/locations/lithunia.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Lithuania</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech UAB</p>
              <p className={styles["img-sub-p1"]}>J. Savickio g. 4, Vilnius 01108, Lithuania</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +37061153994
              </p>
            </div>
          </Grid>
          <Grid
            ref={box7}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/locations/oman.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Oman</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech Limited</p>
              <p className={styles["img-sub-p1"]}>Spring Field International Co LLC</p>
              <p className={styles["img-sub-p1"]}>Post Box No 1423 New Industrial Area, Salalah 211, Sultanate of Oman </p><p className={styles["img-sub-p1"]}></p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" />+968 91118712
              </p>
            </div>
          </Grid>
          <Grid
            ref={box8}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/locations/kazakhstan.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Kazakhstan</h3>
              <p className={styles["img-sub-p"]}>Branch of Data Template Infotech private Limited</p>
              <p className={styles["img-sub-p1"]}>Office 27, 153, Abai Ave , Almaty 050009, Republic of Kazakhstan</p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +7 701 316
                7341
              </p>
            </div>
          </Grid>
          <Grid
            ref={box9}
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={styles["location-content"]}>
            <div className={styles["img-hover-zoom"]}>
              <img
                className={styles["img-res"]}
                id='imghover'
                src={
                  process.env.PUBLIC_URL + "/assets/images/locations/japan.svg"
                }
                alt='Location'
                loading='lazy'
              />
            </div>
            <div>
              <h3 className={styles["img-sub-h"]}>Japan</h3>
              <p className={styles["img-sub-p"]}>Data Template Infotech KK
</p>
              <p className={styles["img-sub-p1"]}>3-23-20, Hinomine kitaku, Kobe, Postal Code 651-1233, Japan </p>
              <p className={styles["img-sub-p2"]}>
                <FormattedMessage id="contact_number_location" /> +81 80 1362
                3995
              </p>
            </div>
          </Grid>
          
        </Grid>
      </div>
    </div>
  );
}
