import React from 'react'
import styles from '../../styles/Partner.module.css'
import { Grid } from "@mui/material";
import GoToTop from '../../GoToTop';

const Astra = () => {
  const goTocomp = () => {
    return window.open('https://premierastra.com/', '__blank')
  }
  return (
    <div className={styles.main}>
        <GoToTop/>
    
    <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid item xl={5} lg={5} md={5} sm={5} xs={12} style={{ paddingTop: '42px' }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>Astra</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/astra/Astra.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
           <div className={styles.PHeading}>

<h3>Astra is the latest addition to Premier Evolvics Pvt Ltd, under the prestigious Premier Mills group that boasts of over 1000 Crores turnover and 5000 employees. 
</h3>

<p>Premier Evolvics Pvt Ltd has been serving customers in 58 countries for over four decades. Evolvic's core team of exceptional engineers have designed best-in-class products for testing machines in textile industries across the world. These products are qualified for global standards and best known for their resilience and reliability.</p>

<p>Premier Evolvics has launched Astra to address the fast-growing technology needs in the Aerospace & Defence landscape. This exclusive division is AS 9100 D certified, helmed by engineers who have worked extensively in Aerospace & Defence related product development and carry a wealth of experience & acuity in developing the right solutions for the right customers.
</p>
<p>Our core team consists of experienced designers from Electrical, Electronic and Mechanical Backgrounds with experience in Defence and Aerospace Domain. We design, develop, manufacture and support the entire life cycle of Mechanical, Electronics, Avionics, Electrical & Robotic products.
</p>


</div>
        </Grid>
      </Grid>
    <div className={styles.buttonPartner}>
    <button className={styles["btn-view"]} onClick={goTocomp}>View More</button>
    </div>
    </div>
  )
}

export default Astra;