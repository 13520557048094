import React, { useRef, useEffect } from "react";
import { Grid, CardMedia } from "@mui/material";
import styles from "../../styles/StretergyExcution.module.css";
import GoToTop from "../../GoToTop";
import { gsap } from "gsap";
import { useHistory } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TweenMax } from "gsap/gsap-core";
import { FormattedMessage } from "react-intl";

const StratergyExecution = () => {
  const history = useHistory();
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const reachoutToContactHAndler = () => {
    history.push("/reachouttous");
  };
  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );

    revealsFirstRef.current.forEach((el, index) => {
      TweenMax.fromTo(
        el,
        1.5,
        {
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          delay: 0,
        },
        {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          delay: 0,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",
          },
        }
      );
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  return (
    <div className={styles.strategyToExecution_container}>
      <GoToTop />
      <div className={styles.stratHead}>
        <div ref={detailRef} className={styles.stratergys}>
          <h4>DATA TEMPLATE</h4>
          <h1><FormattedMessage id="strategy_execution_heading"/></h1>
          <p className={styles.solutionp}>
            <FormattedMessage id="strategy_execution_para"/>
          </p>
        </div>
      </div>
      <div className={styles.stretVedio} ref={addToFirstRef}>
        <CardMedia
          component='img'
          autoPlay
          playsInline
          muted
          loop
          className={styles.mediaFirsts}
          src={
            process.env.PUBLIC_URL +
            "/assets/images/stratergy/strategytoexecution.svg"
          }
        />
      </div>
      <div className={styles.Insights}>
        <div className={styles.stratergy} ref={detailRef}>
          <h4><FormattedMessage id="engage_strategy_exe"/></h4>
          <h3>
            <FormattedMessage id="engage_head_strategy_exe"/>
          </h3>
          <p>
            <FormattedMessage id="engage_para_strategy_exe"/>
          </p>
          <br />
          <h4><FormattedMessage id="discover_strategy_exe"/></h4>
          <h3>
            <FormattedMessage id="discover_head_strategy_exe"/>
          </h3>
          <p>
            <FormattedMessage id="discover_para_strategy_exe"/>
          </p>
        </div>
      </div>

      <div className={styles.twoImages} ref={detailRef}>
        <Grid container spacing={2} className={styles.sustainMainVedio}>
          <img
            autoPlay
            playsInline
            muted
            loop
            className={styles["media_flow"]}
            src={process.env.PUBLIC_URL + "/assets/images/stratergy/Agile.gif"}
            alt="sustain"
          />
        </Grid>
      </div>
      <div className={styles.Insights} ref={detailRef}>
        <div className={styles.stratergy}>
          <h4><FormattedMessage id="define_strategy_exe"/></h4>
          <h3>
            <FormattedMessage id="define_head_strategy_exe"/>
          </h3>
          <p>
            <FormattedMessage id="define_para_strategy_exe"/>
          </p>
        </div>
      </div>

<div className={styles.stratergyblock}>
      <div className={styles.twoImgTextGridMain} ref={detailRef}>
        <Grid className={styles.imgText}>
          <div
            style={{ width: "90%" }}
            className={styles["img-hover-zoom"]}
            id={styles["divimg"]}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/stratergy/strategy1.svg"
              }
              alt='Experiment'
              id='imghover'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.stratergy}>
            <h4><FormattedMessage id="build_strategy_exe"/></h4>
            <h3>
              <FormattedMessage id="build_head_strategy_exe"/>
            </h3>
            <p>
             <FormattedMessage id="build_p1_strategy_exe"/>
            </p>
            <p>
              <FormattedMessage id="build_p2_strategy_exe"/>
            </p>
          </div>
        </Grid>
      </div>
      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText}>
          <div style={{ width: "90%" }} className={styles.stratergy}>
            <h4><FormattedMessage id="support_strategy_exe"/></h4>
            <h3><FormattedMessage id="support_head_strategy_exe"/></h3>
            <p>
              <FormattedMessage id="support_para_strategy_exe"/>
            </p>
          </div>
          <div
            style={{ width: "90%" }}
            className={styles["img-hover-zoom"]}
            id={styles["divimg"]}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/stratergy/strategy2.svg"
              }
              alt='Experiment'
              id='imghover'
            />
          </div>
        </Grid>
      </div>
      </div>
      <div
        style={{ flexDirection: "column", paddingTop: "44px" }}
        className={styles.Insights}>
        <div className={styles.stratergy}>
          <h4><FormattedMessage id="transition_strategy_exe"/></h4>
          <h3><FormattedMessage id="transition_h1_strategy_exe"/></h3>
          <p>
            <FormattedMessage id="transition_p1_strategy_exe"/>
          </p>
          <p>
           <FormattedMessage id="transition_p2_strategy_exe"/>
          </p>
          <h3
            style={{ paddingTop: "45px" }}
            className={styles["transition-head"]}>
            <FormattedMessage id="transition_h2_strategy_exe"/>
          </h3>
        </div>

        <div
          style={{ padding: "30px 30px 26px 0", marginBottom: "-50px"
         }}
          className={styles["strategy-btn"]}>
          <button
            className={styles["btn-views"]}
            onClick={reachoutToContactHAndler}>
              <FormattedMessage id="button_strategy_exe"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StratergyExecution;
