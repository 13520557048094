import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import React, { useState,useEffect } from "react";
import GoToTop from "../../GoToTop";
import * as data from "../../../data.js";
import styles from "../../styles/OpenPosition1.module.css";
import { Form, Formik } from "formik";
import {  useLocation , useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { CheckBox } from "@mui/icons-material";
export default function OpenPosition1() {

  const history = useHistory()
  const [noticePeriod, setNoticePeriod] = useState('');
  const [lastWorking, setLastWorking] = useState('');
  const [hearJob, setHearJob] = useState('');
  const [expectedCTC, setExpectedCTC] = useState('');
  const [Linkdin, setLinkdin] = useState('');
  const [uploadFiles, setUploadFiles] = useState('');
  const [career, setCareer] = React.useState(null);
  const [checkbox, setCheckbox] = useState(false);

  const {state} = useLocation()
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    mob: "",
    file: "",
  };



  const validate = (values) => {
    let errors = {};
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const mob = /^[7-9][0-9]{9}$/;
    const nameregex =/^[a-zA-Z\u00C0-\u017F]+(?:\s[a-zA-Z\u00C0-\u017F]+)*$/;

    if (!values.fname) {
      errors.fname = "First Name is required";
    } else if(values.fname.length > 30){
      errors.fname = 'Only 30 Characters are required!'
    }else if (!nameregex.test(values.fname)) {
      errors.fname = "Invalid name";
    }

    if (!values.lname) {
      errors.lname = "Last Name is required";
    }else if(values.lname.length > 30){
      errors.lname = 'Only 30 Characters are required!'
    }else if (!nameregex.test(values.lname)) {
      errors.lname = "Invalid name";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailregex.test(values.email)) {
      errors.email = "Invalid Email";
    }
    if (!values.mob) {
      errors.mob = "Mobile Number is required";
    } else if (!mob.test(values.mob)) {
      errors.mob = "Invalid Number";
    }
    if (!values.file) {
      errors.file = "Attach file is required";
    }
    

    return errors;
  };
  const privacyPolicyHAndler = () => {
    history.push('/privacyPolicy')
  }
  const termHandler = () => {
   history.push('/termofuse')
  }

  const  onChangeValue = (event) => {
    setNoticePeriod(event.target.value);
  }
  const  lastWorkingHandler = (event) => {
    setLastWorking(event.target.value);
  }
  const  hearJobHandler = (event) => {
    setHearJob(event.target.value);
  }
  const  expectedCTCHandler = (event) => {
    setExpectedCTC(event.target.value);
  }
  const  linkdinHandler = (event) => {
    setLinkdin(event.target.value);
  }
  useEffect(() => {
    if (data && data.wpData && data.wpData[0].contact) {
      setCareer(data.wpData[0].career);
    }
  }, [data]);
  const notify = (data) => toast(data, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
});

async function submitContact(data) {
    let formData = new FormData();
    formData.append("fullName", data.fullName);
    formData.append("email", data.email);
    formData.append("resume", data.file);
    formData.append("phone", data.mob);
    formData.append("linkedinUrl", data.Linkdin);
    formData.append("noticePeriod", data.noticePeriod);
    formData.append("hearJob", data.hearJob);
    formData.append("expectedCTC", data.expectedCTC);
    formData.append("lastWorking", data.lastWorking);
    formData.append("position", data.state);

    await fetch(career, {
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        notify("Thanks for contacting us we will be in touch with you shortly");
      })
      .catch((error) => {
        notify("Thanks for contacting us we will be in touch with you shortly");
      });
  }


  const positionFormSubmitHandler = (values, {resetForm}) => {
    
    const {fname,lname,mob,file,email} = values
    const data = {
    fullName: `${fname}  ${lname}`,
    email,
    mob:Number(mob),
    file,
    noticePeriod,
    lastWorking,
    hearJob,
    expectedCTC,
    Linkdin,
    state
    }
 
    submitContact(data)
    resetForm();
    setNoticePeriod('')
    setLastWorking('')
    setHearJob('')
    setExpectedCTC('')
    setLinkdin('')
    setUploadFiles('')
    setCheckbox(false)
  }
  const CheckBoxHandler = (e) => {
    setCheckbox(e.target.checked)
  }


  return (

    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={positionFormSubmitHandler}
      
    >

      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty
        } = formik;
        return (

          <div className={styles["form1-block"]}>
            <div className={styles.formsub}>
              <GoToTop />
            </div>
            <ToastContainer />
            <Form onSubmit={handleSubmit} className={styles.padform} autoComplete='off'>
              <p className={styles["open-p"]}  name="position">{state}</p>
              <h3 className={styles["Open-H"]}>Apply</h3>
              <p className={styles["open-p1"]}>Personal Details</p>
              <div name='fullName'>
              <label htmlFor="fname" className={styles["form1-label"]}>
                First Name
                <br />
                <TextField
                  id="fname"
                  label=' '
                  name='fname'
                  variant='standard'
                  className={styles["text1-fields"]}
                  size='small'
                  value={values.fname}
                  type='text'
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </label>
              {errors.fname && touched.fname && (
                <span className={styles.error}>{errors.fname}</span>
              )}
              <br />
              <label htmlFor="lname" className={styles["form1-label"]}>
                Last Name
                <br />
                <TextField
                  id="lname"
                  label=' '
                  name='lname'
                  variant='standard'
                  className={styles["text1-fields"]}
                  size='small'
                  type="text"
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  value={values.lname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </label>
              {errors.lname && touched.lname && (
                <span className={styles.error}>{errors.lname}</span>
              )}
              </div>
              <br />
              <label htmlFor="email" className={styles["form1-label"]}>
                Email
                <br />
                <TextField
                  id="email"
                  label=' '
                  variant='standard'
                  className={styles["text1-fields"]}
                  type='email'
                  size='small'
                  name='email'
                  value={values.email}
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </label>
              {errors.email && touched.email && (
                <span className={styles.error}>{errors.email}</span>
              )}
              <br />
              <label htmlFor="mob" className={styles["form1-label"]}>
                Phone
                <br />
                <TextField
                  id='mob'
                  label=' '
                  variant='standard'
                  className={styles["text1-fields"]}
                  size='small'
                  type="text"
                  name="mob"
                  value={values.mob}
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </label>

              {errors.mob && touched.mob && (
                <span className={styles.error}>{errors.mob}</span>
              )}
              <br /> <br />
              

              <input
                type="file"
                
                accept="application/pdf"
                style={{ display: 'none' }}
                id="file"
                name="resume"
                onChange={(event) => {

                  if (event.currentTarget.files && (event.currentTarget.files[0].type=== "application/pdf" ||  event.currentTarget.files[0].type=== "image/jpeg")) {
                    setUploadFiles(event.currentTarget.files[0].name)
                    formik.setFieldValue(
                      "file",
                      event.currentTarget.files[0]
                    )
                  } else{
                    setUploadFiles('Please Upload PDF or JPG')
                  }
                }}
                onBlur={handleBlur}
            
              />
              <label htmlFor="file" className={styles.fileSize}>
                <Button className={styles.buttonChoose} component="span">
                  Attach File
                </Button>
                <span style={{ paddingLeft: '7px' }}>Maximum file size 4MB<p className={styles.uploadFiles}>{uploadFiles}</p></span>
              </label>
             
              {errors.file && touched.file && (
                <span className={styles.error}>{errors.file}</span>
              )}
        
              <br />

              <label className={styles["form1-label"]}>Are you currently in the notice period?</label>
              <br />
              <div onChange={onChangeValue} name="noticePeriod">
              <label className={styles["form2-label"]}>
                <input type='radio' value="No" name='noticePeriod' id='' defaultChecked={noticePeriod === "No"} />

                <span className={styles.radioClick} >No</span>
              </label>
              <br />
              <label className={styles["form2-label"]}>
                <input type='radio' value="Yes" name='noticePeriod' id='' defaultChecked={noticePeriod === "Yes"}/>
                <span className={styles.radioClick} >
                  Yes
                </span>
              </label>
              </div>
              <br />

              <label className={styles["form1-label"]}>
                If you have answered “Yes”,please let us know your Last working date:
                <br />
                <TextField
               
                  variant='standard'
                  name="lastWorking"
                  className={styles["text1-fields"]}
                  size='small'
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  value={lastWorking}
                  onChange={lastWorkingHandler}
                />
              </label>
              <br />
              <label className={styles["form1-label"]}>
                How did you hear about this job?
                <br />
                <TextField
                 
                  name="hearJob"
                  variant='standard'
                  className={styles["text1-fields"]}
                  size='small'
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  value={hearJob}
                  onChange={hearJobHandler}
                />
              </label>
              <br />
              <label className={styles["form1-label"]}>
                Please share your Current and Expected CTC
                <br />
                <TextField
                
                  name="expectedCTC"
                  variant='standard'
                  className={styles["text1-fields"]}
                  size='small'
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  value={expectedCTC}
                  onChange={expectedCTCHandler}
                />
              </label>
              <br />
              <label className={styles["form1-label"]}>
                LinkedIn Profile
                <br />
                <TextField
                  label=' '
                  variant='standard'
                  name="linkedinUrl"
                  className={styles["text1-fields"]}
                  size='small'
                  value={Linkdin}
                  InputProps={{
                    disableUnderline: true, 
                  }}
                  onChange={linkdinHandler}
                />
              </label>

              <br /><br />

              <div className={styles.checkboxItems}>
              <input type='checkbox' className={styles.check}  style={{padding: "0px"}}  checked={checkbox} onChange={CheckBoxHandler}  value={checkbox}/>
              <p  className={styles.acknowledge}>I acknowledge that I read and agree to the <span  className={styles.termPriv} onClick={termHandler}>Terms of Use</span> and <span  className={styles.termPriv} onClick={privacyPolicyHAndler}>Privacy Policy</span>.</p>
                </div>


              <Button variant="outlined" type="submit" style={{ color: "#414141", border: "1px solid #9B9B9B", borderRadius: 0, textTransform: "capitalize" }} disabled={!checkbox}>Send Application</Button>



            </Form>
          </div>
        )
      }}
    </Formik>
  );
}
