import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";
import { FormattedMessage } from "react-intl";

export default function RuleEngine() {
    gsap.registerPlugin(ScrollTrigger);
    const detailRef = useRef(null);
    const detailRef1 = useRef(null);

    useEffect(() => {
        const element = detailRef.current;
        gsap.fromTo(
            element,
            { y: 100, autoAlpha: 0 },
            {
                duration: 3.25,
                y: 5,
                autoAlpha: 4,
                overwrite: "auto",
            }
        );
        const element1 = detailRef1.current;
        gsap.fromTo(
            element1,
            { y: 100, autoAlpha: 0 },
            {
                duration: 3.25,
                y: 5,
                autoAlpha: 4,
                overwrite: "auto",
            }
        );
    }, [])



    return (
        <div className={styles.web_container}>
            <GoToTop />
            <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
                <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={12}
                    style={{ paddingTop: "28px" }}>
                    <div className={styles["enterprise-block"]} >

                        <div>
                            <h3 className={styles["enterprise-h3"]}>
                                Rule Engine
                            </h3>
                        </div>

                    </div>
                </Grid>

                <Grid item xl={7} lg={7} md={7} sm={7} xs={12}    >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/images/blogdrill/Ruleengine/ruleengine.svg"
                        }
                        className={styles["enterprise-img"]}
                        alt='enterprise'
                    />
                </Grid>
            </Grid>

            <div className={styles.enterpriseMain} ref={detailRef1}>
                <Grid container className={styles.ElementEnterprises}>
                    <Grid item>

                    </Grid>
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                        <div>
                            <h3 className={styles.PHeading}>
                                Introduction
                            </h3>

                            <p className={styles.PSubHeading}>
                                A rule engine is a software system that executes business rules using a declarative approach, allowing
                                rules to be separate from the application code.

                                <p className={styles.PSubHeading}>
                                    It evaluates conditions and executes actions based on these rules. This separation of concerns makes
                                    rule engines powerful tools for managing complex business logic that may change frequently without
                                    altering the core application code.
                                    <p className={styles.PSubHeading}>To explain rule-engine in a very simple way. Let’s start with the problem. Suppose if we have to build
                                        a bank application, where we have to implement the logic like mentioned below.
                                        <p className={styles.PSubHeading}>A person is eligible for car loan if he has a monthly salary more than 70K and his credit score is more
                                            than 900 then, approve the car loan and sanction the 60% of requested amount.</p>
                                    </p>
                                </p>
                            </p>
                            <h3 className={styles.PHeading}>
                                Need For Rule Engine
                            </h3>

                            <p className={styles.PSubHeading}>
                                If there are a large number of logics then, how will we search and apply them efficiently?
                                <p className={styles.PSubHeading}>
                                    If logics are frequently changing and we generally code our logic in the application, then how will we
                                    manage or change the code frequently? Design the application such that it can be easily maintained
                                    and understood by business people.
                                    <p className={styles.PSubHeading}>If we have to keep all our business logic at a centralized place and separate from all the applications
                                        then, where will we keep it?
                                        <p className={styles.PSubHeading}>To achieve all these requirements in our application, we can use the rule-engine. But before starting
                                            the rules-engine, let’s go through a few terminologies.</p>
                                    </p>
                                </p>
                            </p>
                            <h3 className={styles.PHeading}>
                                Terminologies
                            </h3>
                            <p className={styles.PSubHeading}>
                                Rule: It is a set of conditions followed by a set of actions. It represents the logic of the system. The rules are mainly
                                represented in the if-then form. It contains mainly two parts, condition, and action. The rule is also known as
                                production.
                                <p className={styles.PSubHeading}>
                                    Inference Engine: An Inference Engine is a component of a Rule Engine that processes and evaluates the rules and facts to derive conclusions or make decisions. It essentially performs the reasoning or logical deduction based on the rules defined within the rule base.
                                    <p className={styles.PSubHeading}>Declarative vs. Imperative: Rule engines use a declarative approach where rules (conditions and actions) are defined separately from the application logic, unlike imperative programming where rules are embedded in procedural code.
                                        <p className={styles.PSubHeading}>Rule Representation: Rules are typically represented using languages like Drools Rule Language (DRL), which is human-readable and allows for easy management and modification.</p>
                                    </p>
                                </p>
                            </p>
                            <h3 className={styles.PHeading}>
                                Benefits of Using Rule Engine in Software Application
                            </h3>
                            <p className={styles.PSubHeading}>
                                Using a rule engine in software systems offers several significant benefits, which contribute to increased
                                flexibility, maintainability, and agility in application development and operation. Here are some key
                                advantages.
                            </p>
                            <p className={styles.PSubHeading}>
                                Separation of Business Rules from Application Code:
                                Rule engines allow business rules to be defined and managed independently of the application logic.
                                This separation reduces the complexity of the main application codebase, making it easier to
                                understand, maintain and modify.
                            </p>
                            <p className={styles.PSubHeading}>
                                Agility and Adaptability: Business rules can change frequently due to evolving requirements, regulations, or market conditions.
                                Rule engines enable quick updates to rules without requiring extensive code changes. This agility
                                facilitates rapid adaptation to new business conditions.
                            </p>
                            <p className={styles.PSubHeading}>
                                Centralized Management And Governance: Rule engines provide centralized management of rules, which promotes consistency and ensures that
                                rules are applied uniformly across the application.
                            </p>
                            <p className={styles.PSubHeading}>
                                Improved Transparency and Traceability: Business rules in a rule engine are typically expressed in a declarative language that is easier to
                                understand and verify compared to complex procedural code. This transparency enhances
                                collaboration between business stakeholders and developers, as everyone can clearly see and discuss
                                the rules that govern application behavior.
                            </p>
                            <p className={styles.PSubHeading}>
                                Enhanced Decision making and Automation: By externalizing business rules into a rule engine, applications can automate decision-making processes based on these rules. This automation reduces manual intervention, speeds up processing times, and improves overall operational efficiency.
                            </p>
                            <p className={styles.PSubHeading}>
                                Scalability and Performance Optimization: Modern rule engines are designed to handle large volumes of rules efficiently. They often incorporate
                                optimization techniques such as rule caching, indexing, and intelligent rule evaluation strategies (e.g.,
                                forward chaining, backward chaining) to improve performance and scalability as rule complexity and
                                data volume increase.
                            </p>
                            <p className={styles.PSubHeading}>
                                Integration Capabilities:
                                Rule engines are typically designed to integrate seamlessly with existing enterprise systems,
                                databases, and other middleware components. This integration capability allows businesses to
                                leverage their existing infrastructure investments while incorporating powerful rule-based decision-
                                making capabilities.
                            </p>
                            <h3 className={styles.PHeading}>
                                Rule Engine Framework in Java
                            </h3>
                            <p className={styles.PSubHeading}>
                                Various Java frameworks like Drools, Easy Rules, and JRules offer different features and integration capabilities. Choosing the right rule engine framework for a Java-based application involves understanding the
                                specific features, integration capabilities, and suitability for your project’s requirements.
                            </p>
                            <h3 className={styles.PHeading}>
                                Drools
                            </h3>
                            <p className={styles.PSubHeading}>
                                Drools, now under the KIE (Knowledge Is Everything) umbrella, is a powerful and versatile open-source rule
                                engine and business rules management system (BRMS) for Java applications.
                            </p>
                            <h3 className={styles.PHeading}>
                                Features of Drools
                            </h3>
                            <p className={styles.PSubHeading}>
                                Rule Engine:
                                Drools provides a robust rule engine capable of executing both forward chaining and backward
                                chaining rules. This allows it to handle complex decision-making processes where rules can infer new
                                information based on existing data (forward chaining) or validate data against rules (backward
                                chaining).
                            </p>
                            <p className={styles.PSubHeading}>
                                Business Rules Management: Drools includes tools for rule authoring, versioning, and lifecycle management. It supports various
                                rule formats, including Drools Rule Language (DRL), decision tables, and guided decision tables.
                            </p>
                            <p className={styles.PSubHeading}>
                                Integration: It integrates well with Java EE and Spring frameworks, allowing seamless embedding of rule
                                evaluation within Java applications. Drools also supports integration with BPMN workflows through
                                tools like KIE Workbench and KIE Server.
                            </p>
                            <p className={styles.PSubHeading}>
                                Complex Event Processing: Drools supports event-driven architectures and complex event processing, enabling real-time
                                decision-making based on streams of events or data.
                            </p>
                            <h3 className={styles.PHeading}>
                                Drools Implementation Strategies and Best Practices
                            </h3>
                            <p className={styles.PSubHeading}>
                                Data Model and Rule Definition
                                <p className={styles.PSubHeading}>
                                    Define a clear data model that reflects the domain entities and their attributes. Rules in Drools are
                                    typically written in DRL, which is a declarative language that allows expressing conditions and actions.
                                </p></p>
                            <p className={styles.PSubHeading}>
                                Session Management
                                <p className={styles.PSubHeading}>
                                    Drools uses a KieSession to manage the lifecycle of rules.
                                </p></p>
                            <p className={styles.PSubHeading}>
                                Strategies Include
                                <p className={styles.PSubHeading}>
                                    Stateful vs. Stateless Sessions:
                                    Choose between stateful sessions, which maintain the state of facts (data) across rule firings, and
                                    stateless sessions, which are lightweight and do not maintain state.
                                    <p className={styles.PSubHeading}>
                                        Batch Vs Stream Processing: Depending on your application requirements, rules can be processed in batches or in real-time
                                        streams using Drools CEP capabilities.
                                    </p></p>
                            </p>
                            <p className={styles.PSubHeading}>
                                Performance Optimization
                                <p className={styles.PSubHeading}>
                                    Indexing and Salience: Use indexing for efficient data retrieval and leverage salience to prioritize rule execution order based
                                    on business priorities.
                                    <p className={styles.PSubHeading}>
                                        Rule Compilation: Precompile rules to bytecode for faster execution
                                    </p></p>
                            </p>

                            <h3 className={styles.PHeading}>
                                Integrating Drools with Java EE and Spring Boot Applications
                            </h3>
                            <p className={styles.PSubHeading}>
                                Embedding Drools
                                <p className={styles.PSubHeading}>
                                    Integrate Drools seamlessly into Java applications using APIs provided by Drools and leveraging
                                    dependency injection frameworks like Spring.
                                </p></p>
                            <p className={styles.PSubHeading}>
                                KIE Workbench & KIE Server
                                <p className={styles.PSubHeading}>
                                    Use KIE Workbench for rule authoring, management, and versioning in a web-based
                                    Environment. KIE Server provides a runtime environment for deploying and executing rules as
                                    services. The KIE architecture is a key part of Drools that helps manage knowledge (rules, processes, and
                                    other artefacts) and their execution. It provides a consistent way to work with different types of
                                    knowledge and integrates them into applications.
                                </p></p>


                            <p className={styles.PSubHeading}>
                                KieContainer
                                <p className={styles.PSubHeading}>
                                    Purpose: Acts as a container for KIE resources like rules, processes, and data.
                                    <p className={styles.PSubHeading}>
                                        Role: Loads and manages knowledge bases, and provides a way to interact with them.
                                    </p></p>
                            </p>
                            <p className={styles.PSubHeading}>
                                KieSession
                                <p className={styles.PSubHeading}>
                                    Purpose: The runtime session where rules are executed.
                                    <p className={styles.PSubHeading}>
                                        Role: Handles the interaction between your application and the rule engine. You insert facts (data)
                                        into the session, and it processes them based on the defined rules.
                                    </p></p>
                            </p>
                            <p className={styles.PSubHeading}>
                                KieBase
                                <p className={styles.PSubHeading}>
                                    Purpose: Represents a knowledge base containing all the rules and processes.
                                    <p className={styles.PSubHeading}>
                                        Role: Provides a compiled representation of all the rules and processes. It’s used to create
                                        KieSessions.
                                    </p></p>
                            </p>
                            <p className={styles.PSubHeading}>
                                KieBaseConfiguration
                                <p className={styles.PSubHeading}>
                                    Purpose: Configures the behaviour of the KieBase.
                                    <p className={styles.PSubHeading}>
                                        Role: Allows fine-tuning of the rule engine, such as specifying rule agenda groups, conflict resolution,
                                        and more.
                                    </p></p>
                            </p>
                            <p className={styles.PSubHeading}>
                                KieFileSystem
                                <p className={styles.PSubHeading}>
                                    Purpose: Manages the knowledge assets within a KIE environment.
                                    <p className={styles.PSubHeading}>
                                        Role: Allows for programmatic access to the files and resources (e.g., rules, processes) used in Drools.
                                    </p></p>
                            </p>
                            <h3 className={styles.PHeading}>
                            Conclusion
                            </h3>
                            <p className={styles.PSubHeading}>
                            In summary, rule engines empower organizations to manage and apply complex business rules effectively, leading to more agile, adaptable, and efficient software systems. They streamline development processes, enhance decision-making capabilities, and improve overall system maintainability and performance.Drools (KIE) is a comprehensive rule engine and BRMS that provides advanced capabilities for managing and executing business rules in Java applications. By following best practices in data modeling, session management, performance optimization, integration, and testing, developers can effectively leverage Drools to build flexible and scalable rule-based systems that meet diverse business needs.</p>
                        </div>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}
