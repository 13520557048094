import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
const Envizage = () => {
  const goTocomp = () => {
    return window.open("https://www.envizage.me/", "__blank");
  };
  return (
    <div className={styles.main}>
      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>Envizage</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL + "/assets/images/Envizage/envizagePage.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
            Envizage empowers their financial services clients to serve their customers with the best products for their needs.
            </h3>

            <p>
            Envizage enables financial services firms – including banks, insurers, retail asset managers
             and pension funds – to better understand, serve and engage with their customers.
             Their software is easy to use either as an API or as whitelabel applications, and helps clients to 
             provide unrivalled digital experiences across financial planning, advice, guidance and 
             wellbeing.
            </p>

            <p>
            With Envizage, you can help your customers achieve what matters the most to them, supporting them to 
            achieve the best financial future possible.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default Envizage;
