import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
const Partner = () => {
  const goTocomp = () => {
    return window.open("https://indigital.co.jp/en/", "__blank");
  };
  return (
    <div className={styles.main}>
      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>INDIGITAL Inc</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL + "/assets/images/indigital/IndigitalDetails.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
            INDIGITAL strive to spread the joy of change by providing guidance 
            and continuous support to those seeking for improvements and new challenges 
            through digital transformation (DX).
            </h3>

            <p>
            We provide services from strategizing to development, and even management, 
            we provide exceptional support through redefining value & business model, 
            innovative ideas & technology to create a leading-edge digital transformation(DX).
            </p>

            <p>
            Our strong belief towards innovative technology and skillful communication has 
            allowed us to maximize our performance, creating highly skilled and diverse teams 
            catered to solve any challenges with great impact. We aim to create a society where 
            diversity is respected and challenges are sought and not feared for.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default Partner;
