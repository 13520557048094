import { useEffect } from "react";
import { useLocation } from "react-router-dom";
  
export default function GoToTop() {
  const routePath = useLocation();
 
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    },0)
  

    return () => {
      clearTimeout(timer);
    }

  }, [routePath.pathname]);
  
  return null;
}
