import React, { useRef, useEffect, useState } from "react";
import styles from "../../styles/Expertise.module.css";
import Grid from "@mui/material/Grid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TweenMax } from "gsap/gsap-core";
import GoToTop from '../../GoToTop'
import * as data from "../../../data.js";
import { Link, useHistory,useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function Industries({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};
  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang") !== null ? params.get("lang") : 'en';
  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);

  const history = useHistory();
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const textRef = useRef([]);
  textRef.current = [];
  revealsFirstRef.current = [];

  const reactOutHAndler = () => {
    history.push('/reachouttous')
  }
  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 50, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 0,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    textRef.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { y: 100, autoAlpha: 0 },
        {
          duration: 3.25,
          y: 5,
          autoAlpha: 4,
          overwrite: "auto",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",

          },
        }
      );
    });
    revealsFirstRef.current.forEach((el, index) => {
      TweenMax.fromTo(
        el,
        1.5,
        {
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          delay: 0,

        },
        {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          delay: 0,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",

          },
        }
      );
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };
  const addToSecondRef = (el) => {
    if (el && !textRef.current.includes(el)) {
      textRef.current.push(el);
    }
  };

  return (
    <>
      <GoToTop />
      <div ref={detailRef} className={styles["expertise-title"]}>
        <h1 className={styles["expertise-content"]}>
          <FormattedMessage id="head_industries" />
        </h1>
      </div>
      <Grid container style={{ paddingBottom: "5%" }}>
        {data.expertise.map((section, index) => {
          return (
            <Grid
              key={section.title}
              className={styles["image-element"]}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ position: "relative" }}
              ref={addToFirstRef}
              item={true}
            >
              <img className={styles["expertise-images"]} src={process.env.PUBLIC_URL + section.img} alt={section.title} />
              <div className={styles["expertise-sec"]} ref={addToSecondRef}>
              <h2 className={styles["expertise-sec-h"]} style={{color: section.color }}>
                  {section.title}
                </h2>
                <Link to={{
                   pathname: `/casestudies/${section.title}`,  
                   search: `?lang=${lang}`,                    
                   state: { industry: section.title },        
                 }}>
                  <button style={{backgroundColor:section.button_background, color:section.button_color}} className={`${styles['expertise-sec-p']} ${section.button_background === "#000000" ? styles["btn-view-industries-white"] : styles["btn-view-industries-black"]}`} ref={addToFirstRef}>
                    <FormattedMessage id="button_industries" />
                  </button>
                </Link>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <div style={{ padding: '0px 0px 73px 135px' }} 
      className={`${styles['expertise-div']}`}>
        
        <button className={`${styles['expertise-sec-p']} ${styles["btn-views"]}`} onClick={reactOutHAndler}>
          <FormattedMessage id="get_in_touch_industries" />{" "}
        </button>
      </div>
    </>
  );
}
