import { Grid } from "@mui/material";
import React from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Sustainability.module.css";
import { FormattedMessage } from "react-intl";

export default function Sustainability() {
  return (
    <div className={styles.sustainability}>
      <GoToTop />
      <div className={styles.sustain}>
        <div>
          <div className={styles["sustain-title"]}>
            <p className={styles.sustainP}><FormattedMessage id="sustainability_p"/></p>
            <h1 className={styles.sustainHeading}>
              <FormattedMessage id="sustainability_h"/>
            </h1>
            <p className={styles["sustain-p"]}>Robert Swan, Author</p>
          </div>
        </div>
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item>
            <div className={styles.firstImg}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/sustainability/sustainability1.svg"
                }
                alt='Sustain'
                loading='lazy'
                className={styles["sustain-earth-img"]}></img>
            </div>
            <div className={styles["sustain-items"]}>
              <p className={styles["sustain-items-p"]}>
                <FormattedMessage id="sustainability_p1"/>
              </p>

              <p className={styles["sustain-para1"]}>
                <FormattedMessage id="sustainability_p2"/>
              </p>

              <p className={styles["sustain-para1"]}>
                <FormattedMessage id="sustainability_p3"/>
              </p>

              <p className={styles["sustain-para1"]}>
                <FormattedMessage id="sustainability_p4"/>
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={styles.sustainMainVedio}>
          <Grid item>
            <img
              component='image'
              autoPlay
              playsInline
              muted
              loop
              className={styles.mediaFirst}
              src={
                process.env.PUBLIC_URL +
                "/assets/images/sustainability/sustainability2.svg"
              }
              alt="sustainability"
            />
          </Grid>
          <Grid item>
            <Grid item>
              <img
                className={styles["sustain_img_experient"]}
                src={
                  process.env.PUBLIC_URL + "/assets/images/sustainability/sustainability3.svg"
                }
                alt='Experiment'
              />
            </Grid>
            <Grid item>
              <img
                component='image'
                autoPlay
                playsInline
                muted
                loop
                className={`${styles.media} ${styles["media_flow"]}`}
                src={
                  process.env.PUBLIC_URL + "/assets/images/sustainability/sustainability4.svg"
                }
                alt="Sustainability"
              />
            </Grid>
          </Grid>
        </Grid>

        <div className={styles.leadingFront}>
          <Grid container spacing={2} style={{ flexDirection: "column" }}>
            <p className={styles["sustain-para1"]}>
              <FormattedMessage id="sustainability_p5"/>
            </p>
          </Grid>
        </div>

        <Grid lg={12} className={styles["sustainimg"]}>
          <Grid item>
            <img
              className={styles["sustain_img_Ice"]}
              src={
                process.env.PUBLIC_URL +
                "/assets/images/sustainability/sustainability5.svg"
              }
              alt='Experiment'
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
