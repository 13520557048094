import React, { useRef, useEffect, useState } from "react";
import { Grid,CardMedia } from '@mui/material'
import styles from "../../styles/Inovation.module.css";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FormattedMessage } from "react-intl";

const Inovation = () => {

    gsap.registerPlugin(ScrollTrigger);
    const detailRef = useRef(null);
    const revealsFirstRef = useRef([]);
    const revealsSecondRef = useRef([]);
    revealsFirstRef.current = [];
    revealsSecondRef.current = [];

    const box1 = useRef(null);
    const box2 = useRef(null);
    const box3 = useRef(null);
    const box4 = useRef(null);

    useEffect(() => {
        const boxes1 = [box1.current, box2.current, box3.current, box4.current];

        boxes1.forEach((el, index) => {
            gsap.set(el, {
                opacity: 0,
                y: 55,
                scale: 0.9,
            });
            gsap.to(el, {
                opacity: 1,
                stagger: 0.2,
                y: 0,
                scale: 1,
                duration: 1.5,
                
                delay: index * 0.2,
                scrollTrigger: {
                    id: `section-${index + 1}`,
                 
                    trigger: el,
                    start: "top bottom-=20",
                    toggleActions: "play none none",
                },
            });
        });
    }, []);

    return (
        <div className={styles.innovation}>
            <GoToTop />
            <div className={`${styles.stratHead} ${styles.invo}`}>
                <div>
                    <h4 style={{fontSize:'16px'}}>DATA TEMPLATE</h4>
                    <h1 style={{fontSize:'50px'}}><FormattedMessage id="inovation_h"/></h1>
                   
                </div>
            </div>

            <div className={styles.stretVedio}>
            <img
            
              width="100%"
              height="auto%"
              alt="invo"
              className={styles.mediaFirsts}
              src={process.env.PUBLIC_URL + "/assets/images/innovation/innovation1.svg"}
            />
            </div>

            <div className={styles.Insights}>
                <div className={styles.invo}>
                    <p><FormattedMessage id="inovation_insight"/></p>
                    <h3><FormattedMessage id="inovation_head"/> </h3>
                    <p><FormattedMessage id="inovation_para"/></p>
                </div>
            </div>

            <div className={styles.twoImgGridMain}>
                <Grid className={styles.twoImgGrid}>
                 
                    <img  src={process.env.PUBLIC_URL + "/assets/images/innovation/innovation2.svg"}  alt="Health" /> 
                    <img className={styles.inovimg} src={process.env.PUBLIC_URL + "/assets/images/innovation/innovation3.svg"} alt="Health" />
                   
                </Grid>
            </div>

            <div className={styles.Insights}>
                <div className={styles.invo}>
                    <h3><FormattedMessage id="inovation_power_h"/></h3>
                    <p><FormattedMessage id="inovation_power_p"/></p>
                </div>
            </div>
            <div className={styles.twoImgGridMain}>
                <Grid className={styles.twoImgGrid}>
                    <img src={process.env.PUBLIC_URL + "/assets/images/innovation/innovation4.svg"}  alt="Health" />
                </Grid>
            </div>

            <div className={styles.Insights}>
                <div className={styles.invo}>
                    <p><FormattedMessage id="inovation_idea_p1"/></p>
                    <h3><FormattedMessage id="inovation_idea_h"/></h3>
                    <p><FormattedMessage id="inovation_idea_p2"/></p>
                    <p><FormattedMessage id="inovation_idea_p3"/></p>
                </div>
            </div>



            
            <div>
          
            </div>
        </div>
    )
}

export default Inovation