import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/Clients.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

export default function Clients() {
  gsap.registerPlugin(ScrollTrigger);

  const [searchClient, setSearchClient] = useState('')
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  }; 



  const filteredClient = data.clientimgs && data.clientimgs.filter((item) => {
     return (  
      item.title.toLowerCase().includes(searchClient.toLowerCase()) || 
       item.subTitle.toLowerCase().includes(searchClient.toLowerCase()) 
  )
})

const searchClientData = (e) => {
  setSearchClient(e.target.value)
}

const clientcloseButtonHandler = () => {
  setSearchClient('')
}


  return (
    <div className={styles.Client_container}>
    <GoToTop/>
      <div className={styles.Client}>
        <div ref={detailRef}>
          <div className={styles["client-title"]}>
            <h1 className={styles["client-heading"]}>
            <FormattedMessage id="clients_head"/>
           
            </h1>
          </div>


        </div>
        
        <div className={styles["Client-img"]}>
          <Grid id='client-part' container spacing={2} className={styles['client-part']}>
            {filteredClient.map((item, index) => {
              return (
                <Grid
                  key={index}
                  className={styles["Client-imgs"]}
                  ref={addToFirstRef}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}>
                  <div className=''>
                  <div className={styles["img-hover-zoom"]}>
                    <img className={styles["client-imghover"]} id="imghover" src={process.env.PUBLIC_URL + item.img} alt={item.title} loading="lazy" />
                  </div>
                    <p className={styles["clienttitle-1"]}>{item.title}</p>
                  </div>
                </Grid>
              );
            })}
            {filteredClient.length === 0 && <p className={styles['no-client-found']}>No Client Found</p>}
          </Grid>
        </div>
      </div>
    </div>
  );
}
