import React, { useRef, useEffect, useState, Suspense } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/Career.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import { Public } from "@mui/icons-material";
import { Link, useHistory, useLocation } from "react-router-dom";
import GoToTop from "../../GoToTop";
import LoadingSpinner from "../../LoadingSpinner";
import { HashLink } from "react-router-hash-link";
import Search from "../CustomSearch/Search";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
const OpenPositions = React.lazy(() => import("../OpenPosition/OpenPosition"));

export default function Career() {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'career_search' });
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang");
  const [showOpenPosition, setShowOpenPosition] = useState(false);
  const history = useHistory();
  const {state} = useLocation();  
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);

  useEffect(() => {
    const boxes1 = [box1.current, box2.current, box3.current];

    const boxes2 = [box4.current, box5.current, box6.current];

    boxes1.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        stagger: 0.2,
        y: 0,
        scale: 1,
        duration: 1.5,

        delay: index * 0.2,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });

    boxes2.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,

        delay: index * 0.3,
        scrollTrigger: {
          id: `section-${index + 1}`,

          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none",
        },
      });
    });
  }, []);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

    revealsSecondRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  const addToSecondRef = (el) => {
    if (el && !revealsSecondRef.current.includes(el)) {
      revealsSecondRef.current.push(el);
    }
  };


  return (
    <div className={styles.career_containers}>
      <GoToTop />
      <div>
        <div className={styles.career}>
          <div ref={detailRef}>
            <div className={styles["career-title"]}>
              <h1>
                <FormattedMessage id="career_title" />
              </h1>
            </div>

            <div
              className={`${styles["input-career-icon"]} ${styles["my-3 ml-auto"]}`}>
                  <Link to='/openpositions'>
                <Search
                  id='search'
                  type='search'
                
                  placeholder={placeholder}
                  tabIndex='1'

                />
              </Link>
            </div>

            <div className={styles["career-content"]}>
              <h3 className={styles["career-content-h"]}>
                <FormattedMessage id="career_content_h" />
              </h3>
              <p className={styles["career-content-p"]}>
                <FormattedMessage id="career_content_p" />
                <br></br>
                <br></br>
                <FormattedMessage id="career_content_p1" />
                <br></br>
                <br></br>
                <FormattedMessage id="career_content_p2" />
                <br />
                <FormattedMessage id="career_content_p3" />
              </p>
            </div>
          </div>
        </div>

        <div className={`${styles["career-content"]} ${styles["bot-pad"]}`}>
          <Grid container spacing={2} id='' ref={addToFirstRef} className={styles["career-images"]}>
            <Grid
              ref={box1}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
              <div className={styles["img-hover-zoom"]}>
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                  <Link 
                    New
                   Message
                   to={{
                   pathname: "/datatemplate",
                   search: `?lang=${lang}`,
                  }}>
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/careers/lifeatdt.svg"
                      }
                      alt='LifeatDT'
                      style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>

              <div>
              <h3 className={styles["img-sub-h"]}><FormattedMessage id="career_img_sub_h"/></h3>
              </div>
            </Grid>
            <Grid
              ref={box2}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
              <div className={styles["img-hover-zoom"]}>
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
              <Link
              New
              Message
              to={{
              pathname: '/perksandbenefits',
              search: `?lang=${lang}`,
             }}
              >
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/careers/perksbenefits.svg"
                      }
                      alt='Career Images'
                      style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>

              <div>
                <h3 className={styles["img-sub-h"]}>
                  <FormattedMessage id="career_perks_benefits" />
                </h3>
              </div>
            </Grid>
            <Grid
              ref={box3}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
              <div className={styles["img-hover-zoom"]}>
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
              <Link 
              New
              Message
              to={{
              pathname: '/recruitmentprocess',
              search: `?lang=${lang}`,
             }}
              >
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                      src='/assets/images/careers/recruitmentnew.svg'
                      alt=''
                      style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>
              <div>
                <h3 className={styles["img-sub-h"]}>
                  <FormattedMessage id="career_recruitment_process" />
                </h3>
              </div>
            </Grid>
            <Grid
              ref={box4}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
                <div
                  className={styles["img-hover-zoom"]}>
                    <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                    <Link 
                    New
                    Message
                    to={{
                    pathname: '/openpositions',
                    search: `?lang=${lang}`,
                   }}
                    >
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                    src='/assets/images/careers/openpositions.svg'
                    alt='Open Positions'
                    style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>

              <div>
                <h3 className={styles["img-sub-h"]}>
                  <FormattedMessage id="career_open_positions" />
                </h3>
              </div>
            </Grid>
            <Grid
              ref={box5}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
              <div className={styles["img-hover-zoom"]}>
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                  <Link 
                  New
                  Message
                  to={{
                  pathname: '/locations',
                  search: `?lang=${lang}`,
                 }}
                  >
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                      src='/assets/images/careers/locations.svg'
                      style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>

              <div>
                <h3 className={styles["img-sub-h"]}>
                  <FormattedMessage id="carrer_locations" />
                </h3>
              </div>
            </Grid>
            <Grid
              ref={box6}
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={styles["career-content1"]}>
              <div className={styles["img-hover-zoom"]}>
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                  <Link
                  New
                  Message
                  to={{
                  pathname: '/reachouttous',
                  search: `?lang=${lang}`,
                 }}
                  >
                    <img
                      className={styles["img-res"]}
                      id='imghover'
                      src='/assets/images/careers/reachouttous.svg'
                      style={{width: "100%"}}
                    />
                  </Link>
                </div>
              </div>

              <div>
                <h3 className={styles["img-sub-h"]}>
                  <FormattedMessage id="career_reach_Out" />
                </h3>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* <div> */}
        <Grid container spacing={2} className={styles["career-block2"]}>
          <Grid
            ref={box1}
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={`${styles["career-content1"]} ${styles["career-imgs"]}`}>
            <div>
            <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                <Link 
                 New
                 Message
                 to={{
                 pathname: '/whatwelookfor',
                 search: `?lang=${lang}`,
                }}
                >
                  <img
                    className={styles["img-res"]}
                    id='imghover'
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/careers/whatwelookfor.svg"
                    }
                    alt='Rectangle'
                    loading='lazy'
                    style={{width: "100%"}}
                  />
                </Link>
              </div>
            </div>

            <div>
              <h3 className={styles["img-sub-head"]}><FormattedMessage id="what_we_look_head"/></h3>
            </div>
          </Grid>
          <Grid
            ref={box4}
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={`${styles["career-content1"]} ${styles["career-imgs"]}`}>
            <div >
                <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                <Link 
                New
                Message
                to={{
                pathname: '/ourpeople',
                search: `?lang=${lang}`,
               }}
                >
                  <img
                    className={styles["img-res"]}
                    id='imghover'
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/careers/ourpeople.svg"
                    }
                    alt='Rectangle 290'
                    loading='lazy'
                    style={{width: "100%"}}

                  />
                </Link>
              </div>
            </div>


            <div>
              <h3 className={styles["img-sub-head"]}><FormattedMessage id="career_ourpeople_h"/></h3>
            </div>
          </Grid>

          <Grid
            ref={box3}
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={`${styles["career-content1"]} ${styles["career-imgs"]}`}>
              <div >
                <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                <Link 
                New
                Message
                to={{
                pathname: '/newsandevents',
                search: `?lang=${lang}`,
               }}
                
                >
                  <img
                    className={styles["img-res"]}
                    id='imghover'
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/careers/newsandevents.svg"
                    }
                    alt='Rectangle 290'
                    loading='lazy'
                    style={{width: "100%"}}
                  />
                </Link>
              </div>
            </div>

            <div>
              <h3 className={styles["img-sub-head"]}><FormattedMessage id="career_newsevents_h"/></h3>
            </div>
          </Grid>
          <Grid
            ref={box2}
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={`${styles["career-content1"]} ${styles["career-imgs"]}`}>
            <div >
              <div style={{height: "600px", width: "600px"}} className={styles["img-hover-zoom"]}>
                <Link 
                New
                Message
                to={{
                pathname: '/sustainability',
                search: `?lang=${lang}`,
               }}
                >
                  <img
                    className={styles["img-res"]}
                    id='imghover'
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/careers/sustain.svg"
                    }
                    alt='Rectangle 290'
                    loading='lazy'
                    style={{width: "100%"}}
                  />
                </Link>
              </div>
            </div>

            <div>
              <h3 className={styles["img-sub-head"]}><FormattedMessage id="career_sustainability_h"/></h3>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <Suspense fallback={<LoadingSpinner />}>
        {showOpenPosition && (
          <OpenPositions />
        )}
      </Suspense> */}
      
    </div>
  );
}
