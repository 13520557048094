import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
import GoToTop from "../../GoToTop";

const YellowPage = () => {
  const goTocomp = () => {
    return window.open("http://ypk.kz/", "__blank");
  };
  return (
    <div className={styles.main}>
      <GoToTop />

      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>Yellow Pages Kazakhstan</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/yellowpage/yellowpage.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
              The company "International Yellow Pages Management Group" began
              its activities in the Republic of Kazakhstan since 1995.
            </h3>

            <p>
              For many years we have been representing the international brand
              "Yellow Pages" in the form of a printed publication - the
              industrial and commercial directory "Yellow Pages of Kazakhstan",
              the website: www.ypk.kz and the mobile application "Yellow Pages
              Kazakhstan".{" "}
            </p>

            <p>
              You can find any information you are interested in on commercial
              and state companies in Kazakhstan, non-profit and non-governmental
              organizations, using the search by name, keywords and available
              categories/subcategories. You can also register your company on a
              paid/free basis or submit a private ad.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default YellowPage;
