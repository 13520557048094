import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";

export default function BI() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const detailRef1 = useRef(null);
  

  useEffect(()=>{
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    const element1 = detailRef1.current;
    gsap.fromTo(
      element1,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  },[])

 
  return (
    <div>
      <GoToTop />
      <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "28px" }}>
          <div className={styles["enterprise-block"]} >
           
            <div>
              <h3 className={styles["enterprise-h3"]}>
                The Enterprise AI Series: Products & Use Cases
              </h3>
            </div>
           
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}    >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/enterprise_img/Drilldown1.svg"
            }
            className={styles["enterprise-img"]}
            alt='enterprise'
          />
        </Grid>
      </Grid>

      <div className={styles.enterpriseMain} ref={detailRef1}>
        <Grid container className={styles.ElementEnterprises}>
          <Grid item>
        
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                Artificial Intelligence (AI) products & use cases
              </h3>

              <p>
                I would like to briefly touch on different types of Artificial
                Intelligence (AI) products & use cases in the enterprise space —
                specifically Customer Relationship Management (CRM). I have been
                part of the AI Platform team at Salesforce, where we built all
                the foundational AI capabilities that power all the CRM
                products.
              </p>

              <p>
                The most common aspects of every large&nbsp; enterprise are
                sales, service, and marketing. An enterprise SaaS company like
                Salesforce serves businesses in the financial, healthcare,
                manufacturing, consumer goods, energy, travel, automotive, etc.
                These businesses want to increase productivity, understand their
                customers better, anticipate obstacles, and improve
                efficiencies.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                Below are a few applications of AI on enterprise data
              </h3>

              <p>
                Sales Lead Generation & Ranking: Using sales data to uncover
                insights on which leads are more likely to convert and rank them
                based on the likelihood of conversion. E-Commerce: Learn
                customer shopping behavior and recommend relevant products.
                Marketing :Learn how customers interact with the marketing
                content on social media and Learn engagement patterns, optimize
                time to send marketing content, and so on.
              </p>
           
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>Customer Service</h3>

              <p>
                Chatbots are built using Natural Language Processing (NLP)
                models to understand customer queries & respond without human
                intervention. Understand customer intention in a service request
                and route requests directly to the appropriate department.
              </p>
            
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/enterprise_img/Drilldown2.svg"
              }
              className={styles["enterprise-img"]}
              alt='enterprise'
            />
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <p>
                The future posts will talk about the challenges &amp; scale
                involved in building these AI products. The focus will be on how
                the multitenant nature of these products poses an interesting
                challenge? At the same time introduces scaling issues on the
                data science &amp; engineering side. Feel free to post
                questions/comments.
              </p>
             
            </div>
          </Grid>
        </Grid>
        
      </div>
    </div>
  );
}
