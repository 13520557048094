import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef ,useState} from "react";
import { westandtogether_imgs } from "../../../data";
import GoToTop from "../../GoToTop";
import styles from "../../styles/WeStandTogether.module.css";
import { FormattedMessage } from "react-intl";

export default function WeStandTogether({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  revealsFirstRef.current = [];

  useEffect(()=>{
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );

    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

  },[]);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  return (
    <div className={styles.weStand_container}>
    <GoToTop/>
      <div className={styles["WeStandTogetherAgainstHate"]}>
        <div ref={detailRef}>
          <div className={styles["WeStandTogether-content"]}>
            <h3 className={styles["westandtogether-h3"]}><FormattedMessage id="our_people_h"/></h3>
        
            <p className={styles["westandtogether-p"]}>
            <FormattedMessage id="our_people_p"/>
            </p>
          </div>
        </div>
        
        <div className={styles["westandtogether-block"]} >
            <p className={styles["westandtogether-p1"]} ref={addToFirstRef}></p>
        <Grid container className={styles["css-11lq3yg-MuiGrid-root"]}>
        {data?.westandtogether_imgs?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  className={styles["westandtogether-imgs"]}
                  ref={addToFirstRef}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}>
                  <div className=''>
                  <div className={styles["img-hover-zoom"]}>
                    <img width="100%"  className={styles["westandtogether-img"]} id="imghover" src={item.img} alt={item.title} loading="lazy"
                   />
                  </div>
                  <div style={{width: '100%'}}>
                    <p className={styles["westandtogether-title"]}>{item.title}</p>
                    <h4 className={styles["westandtogether-subtitle"]}>{item.subTitle}</h4>
                    </div>
                  </div>
                </Grid>
              );
            })}
            
            
        </Grid>
        </div>
      </div>
    </div>
  );
}
